import { memo } from "react";
import { useDataContext } from "../../contexts/DataContext";
import {
  Container,
  Category,
  Title,
  Services,
  Service,
  NamePrice,
  Description,
  ReadMore,
  ButtonContainer,
  Duration,
  Button,
} from "./style";

function ServicesSection({
  readMore,
  // handleReservation,
  // isChoosingMoreServices,
}) {
  const categoriesArray  = [{
    "_id": 1,
    "title": "Nudimo",
    "services": [
      {
        "_id": 101,
        "name": "Klasično šišanje",
        "description": "Klasično šišanje je tradicionalna tehnika koja se izvodi ručno makazama i češljem, " +
            "pružajući precizno oblikovanje i prilagođavanje frizure prema vašim željama i tipu kose. " +
            "Ovo je odličan izbor za prirodni i dugotrajni izgled.",
        "price": "700 RSD",
        "duration": "20 min"
      },
      {
        "_id": 102,
        "name": "Tehnika Fade šišanje",
        "description": "Tehnika Fade šišanja je visoko stilizovana frizura koja kombinuje postepeno smanjenje " +
            "dužine kose sa precizno izrađenim prelazima između kraće i duže kose. Ovaj moderni izgled naglašava " +
            "sofisticiranu estetiku i zahteva veštinu frizera kako bi se postigao besprekoran prelaz između različitih dužina kose. " +
            "Fade šišanje je prava opcija za one koji žele da istaknu svoj stil i individualnost.",
        "price": "900 RSD",
        "duration": "30 minutes"
      },
      {
        "_id": 103,
        "name": "Vojničko šišanje",
        "description": "Vojničko šišanje je izrazito praktična i održava frizura, koja se postiže preciznim " +
            "korišćenjem mašine za šišanje. Ova moderna frizura naglašava jednostavnu eleganciju i funkcionalnost, " +
            "savršeno se uklapajući u vojne i sportske okoline.",
        "price": "600 RSD",
        "duration": "15 minutes"
      },
      {
        "_id": 104,
        "name": "Šišanje duge kose",
        "description": "Šišanje duge kose je idealno za one koji žele osvežiti svoj izgled zadržavajući dužinu kose. " +
            "Naši frizeri su stručnjaci u radu s dugom kosom, bilo da je ravna, kovrdžava ili talasasta. Sa pažljivo " +
            "odabranim tehnikama šišanja i stiliziranja, stvaramo izgled koji je jednostavno prelep. Bez obzira na to " +
            "da li želite romantičan i mekan izgled ili modernu, oštru frizuru, mi ćemo vam pružiti personalizovanu " +
            "uslugu koja će vašu dugu kosu pretvoriti u vašu najbolju karakteristiku.",
        "price": "1000 RSD",
        "duration": "50 minutes"
      },
      {
        "_id": 105,
        "name": "Dečije šišanje",
        "description": "Dečije šišanje je prilagodljiva frizura koja se kreira s obzirom na uzrast i želje deteta. " +
            "Ova zabavna i vesela frizura često uključuje različite oblike, slojeve i boje kako bi se " +
            "izrazila dečja kreativnost i ličnost. " +
            "Dečije šišanje je savršen način da se mališani osećaju posebno i srećno u svom izgledu.",
        "price": "550 RSD",
        "duration": "20 minutes"
      },
      {
        "_id": 106,
        "name": "Korekcija brade",
        "description": "Korekcija brade je pažljivo oblikovanje i uređivanje brade kako bi se postigao savršen izgled. " +
            "Ova usluga omogućava muškarcima da istaknu svoj stil i osobnost kroz precizno izrađene konture i " +
            "pravilno podrezivanje brade.",
        "price": "500 RSD",
        "duration": "10 minutes"
      }
    ]
  }];

  return (
    <Container>
      {categoriesArray?.map((category) => {
        return (
          <Category key={category?._id}>
            <Title>{category?.title}</Title>
            <Services>
              {category?.services?.map((service) => {
                return (
                  <Service key={service?._id}>
                    <NamePrice>
                      <p>{service?.name}</p>
                      <p>{`${service?.price}`}</p>
                    </NamePrice>
                    <Description>{service?.description}</Description>
                    {service?.description !== "" && (
                      <ReadMore onClick={() => readMore(service)}>
                        {/*Ler mais...*/}
                        Opširnije...
                      </ReadMore>
                    )}
                    <ButtonContainer>
                      <Duration>{service?.duration}</Duration>
                      {/*<Button onClick={() => handleReservation(service)}>*/}
                      {/*  {isChoosingMoreServices ? "Adicionar" : "Reservar"}*/}
                      {/*</Button>*/}
                      {/*<Button/>*/}
                    </ButtonContainer>
                  </Service>
                );
              })}
            </Services>
          </Category>
        );
      })}
    </Container>
  );
}

export default memo(ServicesSection);
