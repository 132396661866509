import HeaderSection from "../../components/HeaderSection";
import AboutSection from "../../components/AboutSection";
import Gallery from "../../components/Gallery";
import CommentsSection from "../../components/CommentsSection";
import {Helmet} from "react-helmet";

export default function AboutPage() {
  return (
    <>
        <Helmet>
            <title>Frizer Arsa Galerija</title>
            <meta name="description" content="Najbolje frizure u Kragujevcu i okolini! Galerija naših radova."/>
            <link rel="canonical" href="/galerija"/>
        </Helmet>
        <HeaderSection page="about" title="Galerija" />
        {/*<AboutSection />*/}
        <Gallery />
        {/*<CommentsSection />*/}
    </>
  );
}
