import { About, BookButton } from "./style";
import { memo } from "react";

function Title({ page, title, navTo }) {
  return (
    <>
      {page === "home" ? (
        <>
          <About page={page}>
            Stil, kvalitet, samopouzdanje
            <br />
            <span>naša misija</span>
          </About>

          {/*<BookButton onClick={() => navTo("/servicos")}>*/}
          {/*  Agende seu corte*/}
          {/*</BookButton>*/}
          <BookButton onClick={() => navTo("/cenovnik")}>
            Cenovnik
          </BookButton>
        </>
      ) : (
        <About>{title}</About>
      )}
    </>
  );
}

export default memo(Title);
