import {
  Container,
  AboutContainer,
  InfoContainer,
  Title,
  Description,
  Button,
  ProfileImage,
} from "./style";
// import haircut from "../../assets/haircut.jpeg";
import haircut from "../../assets/ispred1.jpg";
import { useNavigate } from "react-router-dom";

export default function HomeToAboutSection() {
  const navigate = useNavigate();

  return (
    <Container>
      <AboutContainer>
        <InfoContainer>
          <Title>Tu smo za Vas</Title>
          <Description>
            U našem muškom frizerskom salonu, smeštenom u srcu Kragujevca, posvećeni smo pružanju revolucionarne 
            usluge koja će vas osvežiti i podići vaše samopouzdanje na najviši nivo.

            Nezavisno od toga da li žudite za klasičnom elegancijom, smelim modernim izdanjem ili nečim između, 
            mi smo tu da ispunimo vaše želje i omogućimo vam da zablistate. <br/>
            Pregledajte našu galeriju kako biste se inspirisali našim prethodnim radom, jer i vi možete postati deo naše uspešne priče!
          </Description>
          {/*<Button onClick={() => navigate("/sobre")}>Pogledajte galeriju!</Button>*/}
          <Button onClick={() => navigate("/galerija")}>Galerija</Button>
        </InfoContainer>
        <ProfileImage src={haircut} alt="" />
      </AboutContainer>
    </Container>
  );
}
