import HeaderSection from "../../components/HeaderSection";
import HomeToAboutSection from "../../components/HomeToAboutSection";
import HomeToContactUsSection from "../../components/HomeToContactUsSection";
import {Helmet} from "react-helmet";

export default function HomePage() {
  return (
    <>
        {/*<HelmetProvider></HelmetProvider>*/}
        <Helmet>
            <title>Frizer Arsa Cenovnik</title>
            <meta name="description" content="Muški frizerski salon u Kragujevcu! Posetite nas i proverite zašto smo najbolji. Pronađite nas u ulici dr Zorana Đinđića 14,
Kragujevac, Srbija."/>
            <link rel="canonical" href="/"/>
        </Helmet>
        <HeaderSection page={"home"} />
        <HomeToAboutSection />
        <HomeToContactUsSection />
    </>
  );
}
