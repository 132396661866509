import { Container, MapContainer } from "./style";

export default function GoogleMaps() {
  return (
    <Container>
      <MapContainer>
        <iframe
          // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.19665895433!2d-46.50673788446662!3d-23.525428184700765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce60bcf14a5393%3A0x221938016cb62396!2sR.%20Itingu%C3%A7u%2C%201085%20-%20Vila%20R%C3%A9%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003658-010!5e0!3m2!1spt-BR!2sbr!4v1658896946237!5m2!1spt-BR!2sbr"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d179.33482774199481!2d20.917385324664902!3d44.014011664067134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47572127222b2ee1%3A0x1edabe90464057df!2sUlica%20Dr.%20Zorana%20Djindji%C4%87a%2014%2C%20Kragujevac%2034000%2C%20Srbija!5e0!3m2!1ssl!2ssi!4v1694318810020!5m2!1ssl!2ssi"
            allowFullScreen=""
            width="100%" height="400" loading="lazy"
        ></iframe>
      </MapContainer>
    </Container>
  );
}
