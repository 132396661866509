import {
  Container,
  InfosContainer,
  Info,
  Image,
  Title,
  Description,
} from "./style";
import contact from "../../assets/contact.png";
import location from "../../assets/location.png";
import schedule from "../../assets/schedule.png";
import SocialIcons from "../SocialIcons";

export default function ContactInfo() {
  const PHONE_NUMBER = process.env.REACT_APP_PHONE_NUMBER;

  return (
    <Container>
      <InfosContainer>
        <Info>
          <Image src={location} alt="" />
          <Title>Lokacija</Title>
          <Description>
            dr Zorana Đinđića 14,
            <br />
            Kragujevac, Srbija
          </Description>
        </Info>
        <Info>
          <Image src={contact} alt="" />
          <Title>Telefon</Title>
          {/*<Description>{`Tel: (11) ${PHONE_NUMBER}`}</Description>*/}
          <Description>{`Tel: +381 (0)65 568-6981`}</Description>
        </Info>
        <Info>
          <Image src={schedule} alt="" />
          <Title>Radno vreme</Title>
          <Description>
            Pon - Pet: 9:00 - 19:00
            <br />
            Sub: 9:00 - 14:00
          </Description>
        </Info>
      </InfosContainer>
    </Container>
  );
}
