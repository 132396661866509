import {
  Container,
  InsideContainer,
  Title,
  Subtitle,
  Spacer,
  Button,
} from "./style";

import { BsWhatsapp } from "react-icons/bs";
import {AiOutlineInstagram} from "react-icons/ai";

export default function SendMessage() {
  // const PHONE_NUMBER = process.env.REACT_APP_PHONE_NUMBER.replace("-", "");
  // const whastAppLink = `https://api.whatsapp.com/send?phone=5511${PHONE_NUMBER}`;
  const instagramAppLink = `https://instagram.com/muski_frizer_arsa`;

  return (
    <Container>
      <InsideContainer>
        <Title>
          {/*Alguma <span>dúvida?</span>*/}
          Imate <span>pitanja?</span>
        </Title>
        <Spacer />
        {/*<Subtitle>Mande uma mensagem:</Subtitle>*/}
        <Subtitle>Pošaljite nam poruku:</Subtitle>

        <Button onClick={() => window.open(instagramAppLink, "_blank")}>
          Pišite na
          {/*<BsWhatsapp className="whats-icon" />*/}
          <AiOutlineInstagram className="whats-icon"/>
        </Button>
      </InsideContainer>
    </Container>
  );
}
