import HeaderSection from "../../components/HeaderSection";
import ContactInfo from "../../components/ContactInfo";
import GoogleMaps from "../../components/GoogleMaps";
import SendMessage from "../../components/SendMessage";
import {Helmet} from "react-helmet";

export default function ContactUsPage() {
  return (
    <>
        <Helmet>
            <title>Frizer Arsa Kontakt</title>
            <meta name="description" content="Kontakt podaci najboljeg frizerskog salona u Kragujevcu! Adresa, instagram, facebook i drugo."/>
            <link rel="canonical" href="/kontakt"/>
        </Helmet>
        <HeaderSection page="contact-us" title="Kontakt" />
        <ContactInfo />
        <GoogleMaps />
        <SendMessage />
    </>
  );
}
