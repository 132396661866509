import { Container, StyledScrollContainer, Image } from "./style";
import { useState } from "react";
import galleryArray1 from "../../data/Gallery";
import "./gallery.css";
import img1 from "../../assets/slide1.jpg";
import img2 from "../../assets/slide2.jpg";
import img3 from "../../assets/slide3.jpg";
import img4 from "../../assets/slide4.jpg";
import img5 from "../../assets/slide5.jpg";
import img6 from "../../assets/slide6.jpg";
import img7 from "../../assets/slide7.jpg";

export default function Gallery() {
  const [cursorStyle, setCursorStyle] = useState("pointer");

  return (
    <Container>
      <div className="slider1">
          <div className="slide-track1">
              <img src={img1} alt="" className="slide1"/>
              <img src={img2} alt="" className="slide1"/>
              <img src={img3} alt="" className="slide1"/>
              <img src={img4} alt="" className="slide1"/>
              <img src={img5} alt="" className="slide1"/>
              <img src={img6} alt="" className="slide1"/>
              <img src={img7} alt="" className="slide1"/>
              <img src={img1} alt="" className="slide1"/>
              <img src={img2} alt="" className="slide1"/>
              <img src={img3} alt="" className="slide1"/>
              <img src={img4} alt="" className="slide1"/>
              <img src={img5} alt="" className="slide1"/>
              <img src={img6} alt="" className="slide1"/>
              <img src={img7} alt="" className="slide1"/>
              <img src={img1} alt="" className="slide1"/>
              <img src={img2} alt="" className="slide1"/>
              <img src={img3} alt="" className="slide1"/>
              <img src={img4} alt="" className="slide1"/>
              <img src={img5} alt="" className="slide1"/>
              <img src={img6} alt="" className="slide1"/>
              <img src={img7} alt="" className="slide1"/>
              <img src={img1} alt="" className="slide1"/>
              <img src={img2} alt="" className="slide1"/>
              <img src={img3} alt="" className="slide1"/>
              <img src={img4} alt="" className="slide1"/>
              <img src={img5} alt="" className="slide1"/>
              <img src={img6} alt="" className="slide1"/>
              <img src={img7} alt="" className="slide1"/>
              <img src={img1} alt="" className="slide1"/>
              <img src={img2} alt="" className="slide1"/>
              <img src={img3} alt="" className="slide1"/>
              <img src={img4} alt="" className="slide1"/>
              <img src={img5} alt="" className="slide1"/>
              <img src={img6} alt="" className="slide1"/>
              <img src={img7} alt="" className="slide1"/>
          </div>
      </div>


        <StyledScrollContainer
            cursorStyle={cursorStyle}
            onScroll={() => setCursorStyle("grabbing")}
            onEndScroll={() => setCursorStyle("pointer")}
        >
            {galleryArray1.map((img) => (
                <Image key={img.id} src={img.src} alt="" id={img.id}/>
            ))}
        </StyledScrollContainer>
    </Container>
  );
}
