import styled from "styled-components";
import * as variables from "../../styles/variables";
import ScrollContainer from "react-indiana-drag-scroll";

export const Container = styled.div`
  width: 100%;

  position: relative;
  overflow: hidden;


  background-color: ${variables.DARK_GREY};

  padding-bottom: 70px;
  
`;

export const StyledScrollContainer = styled(ScrollContainer)`
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
    
  display: flex;
  justify-content: space-evenly;

  cursor: ${(props) => props.cursorStyle};

  overflow: hidden; /* Hide overflowing images */
  //
  //animation: scrollImages 22s linear infinite; /* Apply the animation to the container */
  //
  //@keyframes scrollImages {
  //  0% {
  //    transform: translateX(0%);
  //  }
  //  100% {
  //    transform: translateX(-100%);
  //  }
  //}
`;

export const Image = styled.img`
  width: 22%;
  height: 22%;
  flex-shrink: 0;

  padding-inline: 6%;
  //flex: 0 0 auto;

  object-fit: cover;

  @media (max-width: 1480px) {
    width: 27%;
    height: 27%;
  }

  @media (max-width: 1023px) {
    width: 39%;
    height: 39%;
  }

  @media (max-width: 620px) {
    width: 100%;
    height: 100%;
  }
`;
