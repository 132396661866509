import photo1 from "../../assets/arsa1.jpg";
import photo2 from "../../assets/arsa2.jpg";
import photo3 from "../../assets/arsa3.jpg";

const galleryArray1 = [
    { id: 1, src: photo3 },
    { id: 2, src: photo2 },
    { id: 3, src: photo1},
];

export default galleryArray1;
